import React from 'react'
import Color from 'color'
// import { css } from 'emotion'
import { css } from '@emotion/core'

// Components
import Button from '../components/button'
import colors from '../styles/colors'
import typography from '../styles/typography'
import common from '../styles/common'

const Hero = props => (
  <div css={hero}>
    <div className="content-container">
      {props.customContent && props.customContent}
      {!props.customContent && (
        <div>
          <h1 className="u_mb-sm"> {props.title} </h1>{' '}
          <h3> {props.subTitle} </h3>
        </div>
      )}
      <div className="actions-container">
        {props.primaryAction && (
          <Button
            useAnchorTag={props.primaryUseAnchorTag || false}
            href={props.primaryActionHref}
            type="light fill u_mt-md"
            size="large"
            text={props.primaryAction}
          />
        )}
        {props.secondaryAction && (
          <Button
            useAnchorTag={props.secondaryUseAnchorTag || false}
            href={props.secondaryActionHref}
            onClick={props.secondaryActionOnClick}
            type="light outline u_mt-md"
            size="large"
            text={props.secondaryAction}
          />
        )}
      </div>
    </div>

    <div className="background-fill" style={props.customBackgroundStyles} />
  </div>
)

function hero() {
  return css`
    position: relative;
    z-index: 0;
    text-align: center;
    margin-bottom: -70px;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

    @media screen and (min-width: ${common.breakpointMedium + 1}px) {
      min-height: 550px;
      padding-top: 160px; 
      padding-bottom: 160px; 
    }
    @media screen and (max-width: ${common.breakpointMedium}px) {
      min-height: 400px;
      padding-top: 140px; 
      padding-bottom: 160px; 
    }

    /* Content */
    div.content-container {
      position: relative;
      max-width: 800px;
      width: 90%;
      margin: 0 auto;
    }

    /* Background */
    div.background-fill {
      z-index: -1;
      position: absolute;
      top: calc(-35px + -1vw);
      left: 0;
      width: 100%;
      height: 100%;
      transform: skewY(-2.25deg);
      background: linear-gradient(
        -150deg,
        ${Color(colors.seaGreen).hex()},
        ${Color(colors.deepSeaBlue).hex()}
      );
      /* background: linear-gradient(
        30deg,
        ${colors.seaGreen},
        ${colors.deepSeaBlue}
      ); */
    }

    /* Types */
    .content-container h1 {
      /* ${typography.mainHeading}; */
      color: ${colors.white};
    }
    .content-container h3 {
      /* ${typography.tertiaryHeading}; */
      color: ${colors.white};
    }

    a, button {
      margin: 0 0.5rem;
    }
  `
}

export default Hero
