import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import Helmet from 'react-helmet'
import color from 'color'
import { Link, navigate } from 'gatsby'

// Images
import webinar_hej from '../images/webinar_hej.png'
import webinar_pib from '../images/webinar_pib.png'
import webinar_og from '../images/webinar_og-image.jpg'
import logo_trustformation from '../images/webinar_logo-trustformation.png'
import logo_ekkoapp from '../images/ekko-app_company-logo_white.svg'

// Components
import Hero from '../components/hero'
import Layout from '../components/layout'

// Styles
import colors from '../styles/colors'
import typography from '../styles/typography'
import common from '../styles/common'

const WebinarPage = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>Ekko App - Webinar</title>
        <meta
          property="og:title"
          content="Webinar: Find dit optimale kanalmiks! - Så medarbejderne ser og hører dig."
        />
        <meta
          property="og:description"
          content={
            'Har du også svært ved at nå alle medarbejdere nemt og effektivt? Og overvejer du at forbedre dit kanalmix, så du ikke spilder dine kræfter på ineffektiv kommunikation? Så se med, når vi gennemgår fordele og ulemper ved interne kanaler og hjælper dig til at forbedre dit kanalmix. Vi har nemlig før hjulpet andre med præcis det problem.'
          }
        />
        <meta property="og:image" content={webinar_og} />
      </Helmet>
      <Hero
        title="Sådan bruger du måling til at ramme plet i intern kommunikation"
        // secondaryAction="Tilmeld dig webinaret nu"
        // secondaryActionOnClick={scrollToSignup}
        customContent={
          <div css={heroCustomContentStyle}>
            <h1 className="u_mb-sm">
              <span id="webinar-title">Webinar:</span>
              Find dit optimale kanalmiks!
            </h1>
            <h3>Så medarbejderne ser og hører dig.</h3>
            <div className="images-container">
              <div className="image-container">
                <img
                  className="speaker-image"
                  src={webinar_hej}
                  alt="Henrik Jensen"
                />
                <div className="text-container">
                  <div>
                    <p>Henrik Jensen</p>
                    <p>CEO, Ekko App</p>
                  </div>
                  <img src={logo_ekkoapp} alt="Ekko App Logo" />
                </div>
              </div>

              <div className="image-container">
                <img
                  className="speaker-image"
                  src={webinar_pib}
                  alt="Pia Baagø"
                />
                <div className="text-container">
                  <div>
                    <p>Pia Baagø Skat-Rørdam</p>
                    <p>Senior konsulent, Trustformation</p>
                  </div>
                  <img src={logo_trustformation} alt="Trustformation Logo" />
                </div>
              </div>
            </div>
          </div>
        }
      />

      <div css={webinar} className="container full-width">
        <div className="inner-container">
          <div className="custom-col-55">
            <div className="attention-container">
              <p>
                Vi har afholdt webinaret, men{' '}
                <Link to="/find-dit-optimale-kanalmiks-til-intern-kommunikation">
                  du kan stadig få indblik i hvordan du finder dit optimale
                  kanalmiks her
                </Link>
              </p>
            </div>
            <p className="lead u_mb-md u_mt-sm">Dato: 28. September, kl. 10</p>

            <p>
              Har du også svært ved at nå alle medarbejdere nemt og effektivt?
              Og overvejer du at forbedre dit kanalmix, så du ikke spilder dine
              kræfter på ineffektiv kommunikation?
            </p>
            <p>
              Så se med, når vi gennemgår fordele og ulemper ved interne kanaler
              og hjælper dig til at forbedre dit kanalmix. Vi har nemlig før
              hjulpet andre med præcis det problem.
            </p>
            <p>
              Du får 45 min. koncentreret intro til kanaler, og helt konkrete
              eksempler fra andre, der allerede har fået mere effekt og gladere
              medarbejdere af deres interne kanaler.
            </p>
            <p className="u_mb-md">Meld dig til få indblik i:</p>
            <ul className="u_pl-md">
              <li>Den afgørende forskel på information og kommunikation</li>
              <li>
                De 3 spørgsmål og 3 metoder, der hjælper dig godt på vej til det
                rette kanalmix
              </li>
              <li>Hvilke fordele og ulemper 7 typiske interne kanaler har </li>
              <li>
                3 konkrete case eksempler fra virksomheder der fået mere
                effektiv intern kommunikation med en medarbejder app
              </li>
              <li>
                Hvordan du kan få mere hjælp til din interne kommunikation
              </li>
            </ul>
            <p className="u_mt-md">
              <a
                href="https://www.linkedin.com/in/piaskatrordam/"
                target="_blank"
                rel="noreferrer"
              >
                Pia Baagø Skat
              </a>{' '}
              er senior konsulent i Trustformation og har 20+ års erfaring med
              at skabe gode arbejdspladser. Har været med til at vinde Året
              Bedste arbejdsplads i 2018 som HR Direktør.
            </p>
            <p>
              <a
                href="https://www.linkedin.com/in/henrikhirtshals/"
                target="_blank"
                rel="noreferrer"
              >
                Henrik Jensen
              </a>
              , CEO I Ekko App, en medarbejder app der øger samhørighed og
              arbejdsglæde hos medarbejdere i en række forskellige brancher.
              Henrik har arbejdet med at skabe effektiv intern kommunikation i
              20 år.
            </p>
            <h2 className="u_mt-md u_mb-md" id="tilmeld_webinar">
              Tilmeld dig herunder
            </h2>

            {/* Begin Mailchimp Signup Form  */}

            <div id="mc_embed_signup">
              <form
                action="https://ekkoapp.us4.list-manage.com/subscribe/post?u=fe5b97a5226e0eb348855de14&amp;id=21e0b41f5d"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                novalidate
                onSubmit={e => {
                  setTimeout(() => {
                    let potentialSuccesEl = document.getElementById(
                      'mce-success-response'
                    )
                    if (potentialSuccesEl.style.display === 'block') {
                      navigate(
                        '/webinar-intern-kommunikation-tak-for-din-tilmelding'
                      )
                    }
                  }, 2000)
                }}
              >
                <div id="mc_embed_signup_scroll">
                  <div className="mc-field-group u_mb-md">
                    <label for="mce-FNAME">Navn (valgfri)</label>
                    <input
                      type="text"
                      name="FNAME"
                      className=""
                      id="mce-FNAME"
                    />
                  </div>

                  <div className="mc-field-group u_mb-md">
                    <label for="mce-MMERGE5">Virksomhed</label>
                    <input
                      type="text"
                      name="MMERGE5"
                      className="required"
                      id="mce-MMERGE5"
                    />
                  </div>

                  <div className="mc-field-group u_mb-md">
                    <label for="mce-EMAIL">Emailadresse</label>
                    <input
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                    />
                  </div>

                  <div
                    id="mergeRow-gdpr"
                    className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
                  >
                    <div className="content__gdpr">
                      <h3 className="u_mb-sm">
                        Hold dig orienteret om intern kommunikation{' '}
                      </h3>
                      <p>
                        Vi laver indhold om effektiv intern kommunikation. Vil
                        du modtage mere af ovenstående indhold fra Ekko App og
                        trustformation der kan hjælpe dig til at blive den
                        skarpeste interne kommunikatør?
                      </p>
                      <fieldset
                        className="mc_fieldset gdprRequired mc-field-group u_mb-md"
                        name="interestgroup_field"
                      >
                        <label className="checkbox subfield" for="gdpr_46658">
                          <input
                            type="checkbox"
                            id="gdpr_46658"
                            name="gdpr[46658]"
                            value="Y"
                            className="av-checkbox gdpr u_ml-md u_mr-sm"
                          />
                          <span>Ja, tak </span>
                        </label>
                        <label className="checkbox subfield" for="gdpr_46666">
                          <input
                            type="checkbox"
                            id="gdpr_46666"
                            name="gdpr[46666]"
                            value="Y"
                            className="av-checkbox gdpr u_ml-md u_mr-sm"
                          />
                          <span>Nej, tak</span>
                        </label>
                      </fieldset>
                      <p>
                        Hvis du ikke vil modtage mails fra os kan du altid
                        afmelde dig nemt via linket i emailen vi sender til dig
                        efter tilmelding.
                      </p>
                    </div>
                    <div className="content__gdprLegal">
                      <p className="footnote">
                        Vi bruger Mailchimp til at sende emails. Ved at klikke
                        tilmeld nedenfor giver du tiladelse til at din
                        information må blive gemt hos Mailchimp. <span> </span>
                        <a href="https://mailchimp.com/legal/" target="_blank">
                          Læs mere om Mailchimps privatlivspolitik her.
                        </a>
                      </p>
                    </div>
                  </div>

                  <div id="mce-responses" className="clear">
                    <div className="response hidden" id="mce-error-response" />
                    <div
                      className="response hidden"
                      id="mce-success-response"
                    />
                  </div>
                  {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                  <div className="hidden-input">
                    <input
                      type="text"
                      name="b_fe5b97a5226e0eb348855de14_21e0b41f5d"
                      tabindex="-1"
                      value=""
                    />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      value="Tilmeld"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button dark fill full-width u_mt-md"
                    />
                  </div>
                </div>
              </form>
            </div>
            {/* This script will translate the formvalidation into Danish. I can't make it work so therefore it is commented out
              <script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]='FNAME';ftypes[1]='text';fnames[5]='MMERGE5';ftypes[5]='text';fnames[0]='EMAIL';ftypes[0]='email';fnames[3]='ADDRESS';ftypes[3]='address'; /*
              * Translated default messages for the $ validation plugin.
              * Locale: DA
              *
              $.extend($.validator.messages, {
                required: "Dette felt er påkrævet.",
                maxlength: $.validator.format("Indtast højst {0} tegn."),
                minlength: $.validator.format("Indtast mindst {0} tegn."),
                rangelength: $.validator.format("Indtast mindst {0} og højst {1} tegn."),
                email: "Indtast en gyldig email-adresse.",
                url: "Indtast en gyldig URL.",
                date: "Indtast en gyldig dato.",
                number: "Indtast et tal.",
                digits: "Indtast kun cifre.",
                equalTo: "Indtast den samme værdi igen.",
                range: $.validator.format("Angiv en værdi mellem {0} og {1}."),
                max: $.validator.format("Angiv en værdi der højst er {0}."),
                min: $.validator.format("Angiv en værdi der mindst er {0}."),
                creditcard: "Indtast et gyldigt kreditkortnummer."
              });}(jQuery));var $mcj = jQuery.noConflict(true);</script> */}
            {/* <!--End mc_embed_signup--> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

function webinar() {
  return css`
    padding: 150px 0;
    background-color: ${colors.ultraLightGrey};

    h1 {
      ${typography.secondaryHeading};
    }
    h2 {
      color: ${colors.deepSeaBlue};
    }

    a {
      color: #17798b;
    }

    li {
      color: ${colors.deepSeaBlue};
      margin-bottom: 1rem;
    }
    input {
      background-color: transparent;
      border: 2px solid ${colors.midGrey};
      margin-top: 5px;
    }
    .custom-col-55 {
      max-width: 600px;
      display: block;
      margin: 0 auto;
    }

    .attention-container {
      background-color: #dde4e6;
      padding: 1.1rem;
      border-left: 5px ${colors.seaBlue} solid;
      border-radius: 5px;
      margin-bottom: 3rem;

      p {
        margin-bottom: 0;
        font-size: 1.1rem;
      }
    }

    form {
      label {
        color: ${colors.deepSeaBlue};
      }

      fieldset {
        border: none;

        label {
          display: flex;
          flex-direction: row;
          align-items: end;
          justify-content: flex-start;
        }

        input {
          width: auto;
          background-color: transparent;
        }
      }
      .hidden {
        display: none;
      }
      .hidden-input {
        position: absolute;
        left: -5000px;
        aria-hidden: true;
      }
      .button {
        ${typography.button} display: inline-block;
        line-height: 45px;
        padding: 0rem 2.5rem;
        text-align: center;
        border-radius: 30px;
        outline: 0px;
        cursor: pointer;
        box-shadow: ${common.boxShadow};
        border: 0px;
        transition: all 200ms ease;
        text-decoration: none;
        position: relative;

        &.full-width {
          width: 100%;
          display: block;
        }

        &.dark.fill {
          background-color: ${colors.seaGreen};
          border: 1px ${colors.seaGreen} solid;
          color: ${colors.white};

          &:hover,
          &:focus {
            background-color: ${color(colors.seaGreen)
              .lighten(0.2)
              .hex()};
            border: 1px ${colors.seaGreen} solid;
          }

          &:active {
            background-color: ${color(colors.seaGreen)
              .darken(0.1)
              .hex()};
            border: 1px
              ${color(colors.seaGreen)
                .darken(0.1)
                .hex()}
              solid;
          }
        }
      }

      .footnote {
        font-size: 0.8rem;
      }
    }

    #mce-responses {
      ${typography.regular} text-align: center;
      font-size: 1.5rem;
      margin: 60px 0 70px 0;

      #mce-success-response {
        color: ${colors.seaGreen};
        text-decoration-line: underline;
      }

      #mce-error-response {
        color: ${colors.red};
      }
    }

    #mc_embed_signup div.mce_inline_error {
      background-color: ${colors.red};
    }

    #mc_embed_signup input.mce_inline_error {
      border-color: ${colors.red};
    }
  `
}

function heroCustomContentStyle() {
  return css`
    max-width: 600px;
    margin: 0 auto;
    h1 {
      color: ${colors.white};
      text-align: left;
      font-size: 2.9rem;
    }

    h3 {
      color: ${colors.white};
      text-align: left;
      font-size: 1.85rem;
    }

    @media screen and (max-width: 600px) {
      margin-top: -50px;

      h1 {
        font-size: 2.6rem;
      }

      h3 {
        font-size: 1.65rem;
      }
    }

    #webinar-title {
      color: ${colors.splashOfBlue};
      display: block;
    }
    .images-container {
      display: flex;
      width: 100%;
      max-width: 600px;
      margin: 70px auto 50px;
      justify-content: space-between;

      .image-container {
        display: flex;

        flex: 50% 1 1;

        img {
          width: 100px;
        }

        img.speaker-image {
          height: 100px;
        }
      }

      .text-container {
        text-align: left;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
          margin-bottom: 0px;
          color: ${colors.white};

          :first-child {
            font-weight: 700;
          }
        }
      }

      @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        margin: 3rem 0 -1.15rem 0;

        .image-container {
          flex: 100% 0 0;
          margin-bottom: 2rem;

          img.speaker-image {
            height: 70px;
            width: 70px;
          }
        }
      }
    }
  `
}

export default WebinarPage
